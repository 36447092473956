import * as A from '../admin'
import {blockMeta} from '../meta'

export interface PostBlockConfig extends A.BlockConfig {
  staticText: string
  staticType: 'markdown' | 'html' | 'text' | string
}

const defaultConfig: PostBlockConfig = {
  staticText: '',
  staticType: '',
}

export const meta = blockMeta({
  name: 'post-content',
  displayName: 'PostBlock',
  title: 'Post Content',
  defaultConfig,
})
