import {UseWithinFieldConfig} from 'quickstart/hooks'
import * as A from '../admin'
import {blockMeta} from '../meta'

export interface SearchBlockGlobalConfig extends A.GlobalBlockConfig {
  metadataMetaTypes: string[]
  tocDepth: boolean
  fulltextDepth: boolean
  showPageThumbnails: boolean
  fields: {
    terms: {
      customTips: string
    }
    within: UseWithinFieldConfig
  }
  customSearchConfigJson: string
}

const defaultGlobalConfig: SearchBlockGlobalConfig = {
  metadataMetaTypes: ['Book', 'Video'],
  tocDepth: true,
  fulltextDepth: true,
  showPageThumbnails: true,
  fields: {
    terms: {customTips: ''},
    within: {mode: 'context', allOptionLabel: 'All content'},
  },
  customSearchConfigJson: '',
  FREEMARKER: {
    'fields.terms.customTips': 'markdown',
  },
}

export const meta = blockMeta({
  name: 'search',
  displayName: 'SearchBlock',
  title: 'Search',
  defaultGlobalConfig,
})
