import * as A from '../admin'
import {blockMeta} from '../meta'

export interface StaticPageBlockConfig extends A.BlockConfig {
  staticText: string
  staticType: 'markdown' | 'html' | 'text' | string
  container: 'default' | 'wider' | 'none'
}

const defaultConfig: StaticPageBlockConfig = {
  staticText: '',
  staticType: '',
  container: 'default',
}

export const meta = blockMeta({
  name: 'static-page-content',
  displayName: 'StaticPageBlock',
  title: 'Static Page Content',
  defaultConfig,
})
