import * as A from '../admin'
import {blockMeta} from '../meta'

export interface CartBlockConfig extends A.BlockConfig {
  emptyMessage: string
  summaryMessage: string
}

const defaultConfig: CartBlockConfig = {
  emptyMessage: '',
  summaryMessage: '',
  FREEMARKER: {
    emptyMessage: 'markdown',
    summaryMessage: 'markdown',
  },
}

export interface CartBlockGlobalConfig {
  url: string
}

const defaultGlobalConfig: CartBlockGlobalConfig = {
  url: '/cart',
}

export const meta = blockMeta({
  name: 'cart',
  displayName: 'CartBlock',
  title: 'Cart',
  defaultConfig,
  defaultGlobalConfig,
})
