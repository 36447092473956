import {
  BlockMeta,
  DefaultGlobalMigrateProps,
  DefaultMigrateProps,
  GlobalMigrate,
  GlobalMigrateWrapper,
  Migrate,
  MigrateWrapper,
} from 'quickstart/types'
import {clone, deepMerge, IS_STORYBOOK, IS_TEST, logger} from 'tizra'

const log = logger('blocks/meta')

export interface BlockMetaProps<C, G, N extends string> {
  name: N
  displayName: string
  title: string
  defaultConfig?: C
  defaultGlobalConfig?: G
  globalMigrate?: GlobalMigrate<G, any>
  migrate?: Migrate<C, any>
}

const defaultGlobalMigrate =
  <G>(defaultGlobalConfig: G | undefined) =>
  ({globalConfig}: DefaultGlobalMigrateProps): G =>
    (defaultGlobalConfig &&
      deepMerge(defaultGlobalConfig)(globalConfig as any)) as G

const defaultMigrate =
  <C>(defaultConfig: C | undefined) =>
  ({config}: DefaultMigrateProps): C =>
    (defaultConfig && deepMerge(defaultConfig)(config as any)) as C

export const blockMeta = <
  C extends object | undefined,
  G extends object | undefined,
  N extends string,
>({
  defaultConfig,
  defaultGlobalConfig,
  displayName,
  name,
  title,
  migrate: blockMigrate = defaultMigrate(defaultConfig),
  globalMigrate: blockGlobalMigrate = defaultGlobalMigrate(defaultGlobalConfig),
}: BlockMetaProps<C, G, N>): BlockMeta<C, G, N> => {
  const blog = log.logger(displayName)

  // Upgraded globalMigrate to clone and pass selected globalConfig.
  const globalMigrate: GlobalMigrateWrapper<G> = props => {
    // AdminWrapper passes globalConfig since it reads/writes textarea,
    // contrast normal operation which reads globalConfigs from context.
    const globalConfig: G =
      'globalConfig' in props ? props.globalConfig
      : props.context?.globalConfig ?
        props.context.globalConfig[name] || defaultGlobalConfig
      : IS_STORYBOOK || IS_TEST ?
        defaultGlobalConfig // XXX: really?
      : (log.error(`missing globalConfig[${name}] in context, where are we?`),
        defaultGlobalConfig)
    const hacks =
      'adminContext' in props ?
        props.adminContext?.hacks ||
        (log.error('missing hacks in adminContext, where are we?'), {})
      : props.context?.hacks ||
        (log.error('missing hacks in context, where are we?'), {})
    let migratedGlobalConfig = blockGlobalMigrate({
      ...props,
      globalConfig: clone(globalConfig),
      hasHack: s => !!hacks[s],
    })
    if (migratedGlobalConfig) {
      if (defaultGlobalConfig && 'FREEMARKER' in defaultGlobalConfig) {
        migratedGlobalConfig = {
          ...migratedGlobalConfig,
          FREEMARKER: defaultGlobalConfig.FREEMARKER,
        }
      } else if ('FREEMARKER' in migratedGlobalConfig) {
        delete migratedGlobalConfig.FREEMARKER
      }
    }
    // This gets called from blockStoryMeta prior to capture of console logging.
    if (!IS_TEST) blog.debug?.({...props, name, migratedGlobalConfig})
    return migratedGlobalConfig
  }

  // Upgraded migrate to pass hacks. If the block needs to access
  // unmigrated global config, it should look in context.
  const migrate: MigrateWrapper<C> = props => {
    const hacks =
      'adminContext' in props ?
        props.adminContext?.hacks ||
        (log.error('missing hacks in adminContext, where are we?'), {})
      : props.context?.hacks ||
        (log.error('missing hacks in context, where are we?'), {})
    let migratedConfig = blockMigrate({
      ...props,
      config: clone(props.config),
      hasHack: s => !!hacks[s],
    })
    if (migratedConfig) {
      if (defaultConfig && 'FREEMARKER' in defaultConfig) {
        migratedConfig = {
          ...migratedConfig,
          FREEMARKER: defaultConfig.FREEMARKER,
        }
      } else if ('FREEMARKER' in migratedConfig) {
        delete migratedConfig.FREEMARKER
      }
    }
    // This gets called from blockStoryMeta prior to capture of console logging.
    if (!IS_TEST) blog.debug?.({...props, name, migratedConfig})
    return migratedConfig
  }

  const meta: BlockMeta<C, G, N> = {
    defaultConfig: defaultConfig as C,
    defaultGlobalConfig: defaultGlobalConfig as G,
    displayName,
    globalMigrate,
    migrate,
    name,
    title,
  }

  return meta
}
