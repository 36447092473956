import * as A from '../admin'
import {blockMeta} from '../meta'

export interface ResetPasswordBlockConfig extends A.BlockConfig {
  heading: string
  message: string
}

const defaultConfig: ResetPasswordBlockConfig = {
  heading: 'Reset Password',
  message: '',
  FREEMARKER: {
    message: 'markdown',
  },
}

export const meta = blockMeta({
  name: 'reset-password',
  displayName: 'ResetPasswordBlock',
  title: 'Reset Password',
  defaultConfig,
})
