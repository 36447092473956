import {deepMerge} from 'tizra'
import * as A from '../admin'
import {blockMeta} from '../meta'

// TODO Refactor using A.BrowseSpec and A.BrowseConfig. The challenges are:
//
// 1. BookshelfBlock has its own useBookshelfSearch. This means that useBrowse
//    will need to either (a) accept a replacement search argument, or (b)
//    support the special workarounds that are currently in useBookshelfSearch.
//
// 2. BookshelfBlock provides a sort control that we don't have on any other
//    Browse implementation so far. That will need to be rolled into useBrowse.

export interface BookshelfBlockSortProp {
  name: string
  defaultDirection: 'ascending' | 'descending'
  label: string
}

export interface BookshelfBlockConfig {
  display: 'covers' | 'images'
  metaTypes: string[]
  requiredAdminTags: string
  excludedAdminTags: string
  sort: {
    props: BookshelfBlockSortProp[]
  }
  addContent: {
    enabled: boolean
  }
}

export const defaultConfig: BookshelfBlockConfig = {
  display: 'covers',
  metaTypes: ['Book', 'Video'],
  requiredAdminTags: '',
  excludedAdminTags: '',
  sort: {
    props: [
      {name: 'Title', defaultDirection: 'ascending', label: 'Title'},
      {name: 'Authors', defaultDirection: 'ascending', label: 'Author'},
    ],
  },
  addContent: {
    enabled: false,
  },
}

const migrate: A.Migrate<BookshelfBlockConfig> = ({config: unknownConfig}) => {
  let config = {...(unknownConfig as any)}

  // Accidentally allowed cards for BookshelfBlock, should always be either
  // covers with text, or just covers.
  if (config?.display === 'cards') {
    config = {...config, display: 'covers'}
  }

  return deepMerge(defaultConfig)(config)
}

export interface BookshelfBlockGlobalConfig {
  title: string
  url: string
}

const defaultGlobalConfig: BookshelfBlockGlobalConfig = {
  title: 'My Bookshelf',

  // Don't change this default here. Change it in the pattern site instead. If
  // you change it here, it will apply to any sites that haven't saved their
  // global config, and that will break existing bookshelves on ~userLicenses
  url: '',
}

export const meta = blockMeta({
  name: 'bookshelf',
  displayName: 'BookshelfBlock',
  title: 'Bookshelf',
  defaultGlobalConfig,
  defaultConfig,
  migrate,
})
