import {AuthAgent} from 'quickstart/types'
import {deepMerge} from 'tizra'
import * as A from '../admin'
import {blockMeta} from '../meta'

export interface SignInBlockConfig extends A.BlockConfig {
  authAgent: AuthAgent | ''
  heading: string
  message: string
}

const defaultConfig: SignInBlockConfig = {
  authAgent: '',
  heading: 'Sign In',
  message: '',
  FREEMARKER: {
    message: 'markdown',
  },
}

export interface SignInBlockGlobalConfig extends A.GlobalBlockConfig {
  authAgent: AuthAgent
  modal: {enabled: boolean}
  passwordReset: {enabled: boolean; url: string}
  registration: {enabled: boolean; url: string}
  signin: {url: string}
}

const defaultGlobalConfig: SignInBlockGlobalConfig = {
  authAgent: 'tizra',
  modal: {
    enabled: true,
  },
  passwordReset: {
    enabled: true,
    url: '/~passwordHelp',
  },
  registration: {
    enabled: true,
    url: '/~registerUser',
  },
  signin: {
    url: '/signin',
  },
}

const globalMigrate: A.GlobalMigrate<SignInBlockGlobalConfig> = ({
  globalConfig: unknownGlobalConfig,
}) => {
  let globalConfig = {
    ...(unknownGlobalConfig as any),
  } as Partial<SignInBlockGlobalConfig>

  // The defaultGlobalConfig originally had signin.url="/login" which is
  // a reserved path in Tizra. If we find that in the config, change it to the
  // new default.
  if (globalConfig?.signin?.url === 'login') {
    globalConfig = {
      ...globalConfig,
      signin: {
        ...globalConfig.signin,
        url: defaultGlobalConfig.signin.url,
      },
    }
  }

  return deepMerge(defaultGlobalConfig)(globalConfig)
}

export const meta = blockMeta({
  name: 'signin',
  displayName: 'SignInBlock',
  title: 'Sign In',
  defaultConfig,
  defaultGlobalConfig,
  globalMigrate,
})
