import * as A from '../admin'
import {blockMeta} from '../meta'

export interface ImageBlockConfig extends A.BlockConfig {
  altText: string
  imageUrl: string
  linkUrl: string
  tizraId: string
  width: string
}

const defaultConfig: ImageBlockConfig = {
  altText: '',
  imageUrl: '',
  linkUrl: '',
  tizraId: '',
  width: 'bleed',
}

export const meta = blockMeta({
  name: 'image',
  displayName: 'ImageBlock',
  title: 'Image',
  defaultConfig,
})
