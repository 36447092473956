import {useMemo} from 'react'
import {useBlockContext} from './useBlockContext'

import {meta as abstractMeta} from 'quickstart/blocks/AbstractBlock/meta'
import {meta as attachmentsMeta} from 'quickstart/blocks/AttachmentsBlock/meta'
import {meta as bookshelfMeta} from 'quickstart/blocks/BookshelfBlock/meta'
import {meta as browseMeta} from 'quickstart/blocks/BrowseBlock/meta'
import {meta as cartMeta} from 'quickstart/blocks/CartBlock/meta'
import {meta as detailsMeta} from 'quickstart/blocks/DetailsBlock/meta'
import {meta as footerMeta} from 'quickstart/blocks/FooterBlock/meta'
import {meta as globalMeta} from 'quickstart/blocks/GlobalBlock/meta'
import {meta as headerMeta} from 'quickstart/blocks/HeaderBlock/meta'
import {meta as headlineMeta} from 'quickstart/blocks/HeadlineBlock/meta'
import {meta as imageMeta} from 'quickstart/blocks/ImageBlock/meta'
import {meta as markdownMeta} from 'quickstart/blocks/MarkdownBlock/meta'
import {meta as navMeta} from 'quickstart/blocks/NavBlock/meta'
import {meta as postMeta} from 'quickstart/blocks/PostBlock/meta'
import {meta as redemptionMeta} from 'quickstart/blocks/RedemptionBlock/meta'
import {meta as registerUserMeta} from 'quickstart/blocks/RegisterUserBlock/meta'
import {meta as resetPasswordMeta} from 'quickstart/blocks/ResetPasswordBlock/meta'
import {meta as searchMeta} from 'quickstart/blocks/SearchBlock/meta'
import {meta as searchWithinMeta} from 'quickstart/blocks/SearchWithinBlock/meta'
import {meta as signInMeta} from 'quickstart/blocks/SignInBlock/meta'
import {meta as staticPageMeta} from 'quickstart/blocks/StaticPageBlock/meta'
import {meta as subnavMeta} from 'quickstart/blocks/SubnavBlock/meta'
import {meta as themeMeta} from 'quickstart/blocks/ThemeBlock/meta'
import {meta as tocMeta} from 'quickstart/blocks/TocBlock/meta'

const metas = {
  [abstractMeta.name]: abstractMeta,
  [attachmentsMeta.name]: attachmentsMeta,
  [bookshelfMeta.name]: bookshelfMeta,
  [browseMeta.name]: browseMeta,
  [cartMeta.name]: cartMeta,
  [detailsMeta.name]: detailsMeta,
  [footerMeta.name]: footerMeta,
  [globalMeta.name]: globalMeta,
  [headerMeta.name]: headerMeta,
  [headlineMeta.name]: headlineMeta,
  [imageMeta.name]: imageMeta,
  [markdownMeta.name]: markdownMeta,
  [navMeta.name]: navMeta,
  [postMeta.name]: postMeta,
  [redemptionMeta.name]: redemptionMeta,
  [registerUserMeta.name]: registerUserMeta,
  [resetPasswordMeta.name]: resetPasswordMeta,
  [searchMeta.name]: searchMeta,
  [searchWithinMeta.name]: searchWithinMeta,
  [signInMeta.name]: signInMeta,
  [staticPageMeta.name]: staticPageMeta,
  [subnavMeta.name]: subnavMeta,
  [themeMeta.name]: themeMeta,
  [tocMeta.name]: tocMeta,
} as const

export const useGlobalBlockConfig = <N extends keyof typeof metas>(name: N) => {
  const context = useBlockContext()
  return useMemo(
    () =>
      metas[name].globalMigrate({
        context,
      }) as (typeof metas)[N]['defaultGlobalConfig'],
    [name, context],
  )
}
