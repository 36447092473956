import {SparseField} from '../types'
import * as R from 'rambdax'

export const terms: SparseField<string, false> = {
  label: '',
  defaultValue: '',
  widget: 'input',
  filterTags: {
    show: false,
  },
  adjuncts: ['depth'],
  api: {
    initialize: ({config, metaTypes, params, utils: {log}}) => {
      // Ensure terms and depth are present so that contribute will be
      // called. Also default metaTypes according to depth.
      const depth = (params.depth ||
        (log.error(`missing params.depth, assuming fulltext`),
        'fulltext')) as keyof typeof config.metaTypes
      if (!metaTypes) {
        metaTypes =
          log.assert(
            config.metaTypes[depth],
            `missing metaTypes for depth=${depth}, making guesses`,
          ) ||
          (R.piped(config.metaTypes, R.values, R.flatten, R.uniq) as string[])
      }
      return {
        metaTypes,
        params: {
          depth,
          terms: '',
          ...params,
        },
      }
    },
    contribute: ({
      chat,
      config,
      metaTypes,
      options: {hittingOn, snippet},
      params: {depth, terms},
      sp,
      utils: {log, toTerms, toTokens},
    }) => {
      const {fieldDefs, quickSearchFields} = config
      const tokens = toTokens(terms as string)
      const termsMetaTypes = new Set()
      const haveTerms = !!tokens.length

      // Don't search toc or pages unless there are terms.
      // https://github.com/Tizra/search/issues/204
      if (haveTerms || chat.handWavyTerms) {
        // The user has supplied terms, or there is a facet that supplies terms,
        // e.g. apaState.
      } else if (depth === 'metadata') {
        // Show metadata results (list of publications) even if there are no
        // terms. This also populates hit counts, though some counts will go up
        // once there are full-text page/toc results.
      } else if (!config.depths.includes('metadata') && hittingOn) {
        // When configured to show only page/toc results (APA), then allow hit
        // counts to proceed, otherwise they display empty.
      } else {
        log.debug?.(`skipping empty terms search with depth=${depth}`)
        return null
      }

      // Clone the parts we'll touch.
      sp = {
        ...sp,
        all: [...sp.all],
        any: [...sp.any],
      }

      if (haveTerms) {
        switch (depth) {
          case 'fulltext':
            sp.all.push(toTerms(tokens, metaTypes, 'pageFullText', 'strict'))
            metaTypes.forEach(m => termsMetaTypes.add(m))
            if (snippet) {
              sp.snippetProp = 'pageFullText'
              sp.snippetQuery = toTerms(tokens, '', 'pageFullText')
            }
            break
          case 'toc':
            sp.all.push(
              toTerms(
                tokens.map(t => (t.op === '' ? t.clone({op: '+'}) : t)),
                metaTypes,
                'toc-all-titles',
                'strict',
              ),
            )
            sp.all.push(
              toTerms(
                tokens
                  .filter(t => t.op !== '-')
                  .map(t => (t.op === '+' ? t.clone({op: ''}) : t)),
                metaTypes,
                'toc-title',
                'strict',
              ),
            )
            metaTypes.forEach(m => termsMetaTypes.add(m))
            break
          default:
            // metadata search
            for (const name of quickSearchFields) {
              for (const def of fieldDefs.filter(d => d.name === name)) {
                if (metaTypes.includes(def.metaType)) {
                  sp.any.push(toTerms(tokens, def.metaType, name, 'strict'))
                  termsMetaTypes.add(def.metaType)
                  if (def.type === 'html' && snippet) {
                    // aka Abstract
                    sp.snippetProp = name
                    sp.snippetQuery = toTerms(tokens, def.metaType, name)
                  }
                } else {
                  log.debug?.(
                    `skipping quickSearchFields.${name} with metaType=${def.metaType}`,
                  )
                }
              }
            }
            break
        }
      } else {
        // No terms, just restrict result to list of meta-types.
        // This still qualifies as an empty query unless there are
        // other props (facets) that will set options.haveProps
        metaTypes.forEach(m => {
          sp.any.push(`metaType:${m}`)
          termsMetaTypes.add(m)
        })
      }

      return {
        sp,
        chat: {
          ...chat,
          // Pass back terms meta-types for sort
          sortMetaTypes: Array.from(termsMetaTypes).sort(),
          // Pass back tokens for debug logging
          tokens,
        },
        haveProps: false,
        haveTerms,
      }
    },
  },
}
