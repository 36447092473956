import * as A from '../admin'
import {blockMeta} from '../meta'

export interface TocBlockConfig extends A.BlockConfig {
  heading: string
  showPageNums: boolean
  style: 'lined' | 'bars' | 'plain'
  labelFreeContent: boolean
}

const defaultConfig: TocBlockConfig = {
  heading: 'Table of Contents',
  showPageNums: true,
  style: 'lined',
  labelFreeContent: false,
}

export const meta = blockMeta({
  name: 'content-toc',
  displayName: 'TocBlock',
  title: 'Table of Contents',
  defaultConfig,
})
