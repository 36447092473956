import * as A from '../admin'
import {blockMeta} from '../meta'

export interface RegisterUserBlockConfig extends A.BlockConfig {
  heading: string
  message: string
}

const defaultConfig: RegisterUserBlockConfig = {
  heading: 'Create Account',
  message: '',
  FREEMARKER: {
    message: 'markdown',
  },
}

export const meta = blockMeta({
  name: 'register-user',
  displayName: 'RegisterUserBlock',
  title: 'Register User',
  defaultConfig,
})
