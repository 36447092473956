import * as AK from '@ariakit/react'
import {getIn} from 'final-form'
import * as R from 'rambdax'
import {
  Children,
  ComponentProps,
  FC,
  Fragment,
  ReactElement,
  ReactNode,
  isValidElement,
  useEffect,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as Final from 'react-final-form'
import * as Listen from 'react-final-form-listeners'
import {
  FiArrowRight,
  FiBook,
  FiCheck,
  FiChevronDown,
  FiCpu,
  FiLink,
  FiMenu,
  FiMoreHorizontal,
  FiMoreVertical,
  FiPlus,
  FiPlusSquare,
  FiTrash,
  FiUser,
} from 'react-icons/fi'
import {RxDragHandleDots2} from 'react-icons/rx'
import {
  Falsish,
  IS_DEV,
  IS_STORYBOOK,
  IS_TEST,
  dropWhile,
  ensureArray,
  logger,
  notNullish,
  takeWhile,
  truthy,
} from 'tizra'
import {useAdminContext} from './hooks'
import * as S from './styles'

const log = logger('blocks/admin')

interface AdminWindow extends Window {
  hideDialog: (el: HTMLElement) => void
  navOut: (code: string, s: 'eval') => void
}

declare let window: AdminWindow

/**
 * Add data-testid in storybook and under test.
 */
export const tid = (...ids: unknown[]) => {
  if (!IS_STORYBOOK && !IS_TEST) return null
  const id = ids
    .filter(notNullish)
    .map(s => `${s}`.toLowerCase().replace(/[\W_]+/g, '-'))
    .filter(truthy)
    .join('--')
  if (!id) return null
  return {'data-testid': id}
}

/**
 * Augment style prop with display: none when hidden
 */
const displayNone = (hidden: boolean) => ({
  ...(hidden && {display: 'none'}),
})

export type {Admin, GlobalMigrate, Migrate} from 'quickstart/types'
export * from './hooks'
export {
  Box,
  P,
  Stack,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Vr,
} from './styles'
export * from './utils'

export const GroupTitle = S.GroupTitle

interface GroupProps extends Omit<ComponentProps<typeof S.Group>, 'title'> {
  hidden?: boolean
  title?: ReactNode
}

export const Group = ({
  children,
  hidden = false,
  title,
  ...props
}: GroupProps) => {
  const {global} = useAdminContext() as any
  return (
    <S.GroupWrapper style={displayNone(hidden)}>
      <S.Group {...props}>
        {title && <GroupTitle>{title}</GroupTitle>}
        {children}
      </S.Group>
      {!global && <hr />}
    </S.GroupWrapper>
  )
}

const SubGroupTitle = (props: any) => <S.SubGroupTitle {...props} />

type FragmentElement = ReactElement<
  ComponentProps<typeof Fragment>,
  typeof Fragment
>

const isFragmentElement = (child: unknown): child is FragmentElement =>
  (child as any)?.type === Fragment

const defrag: typeof Children.toArray = children =>
  Children.toArray(children).flatMap(child =>
    isFragmentElement(child) ? defrag(child.props.children) : [child],
  )

export const SubGroup = ({
  children,
  title,
  ...props
}: ComponentProps<typeof S.SubGroup> & {title?: string}) => (
  <S.SubGroup {...props}>
    {title && <SubGroupTitle>{title}</SubGroupTitle>}
    {children}
  </S.SubGroup>
)

const TabButton = (
  props: Omit<ComponentProps<typeof S.TabButton>, 'children'> & {
    children: string
  },
) => <S.TabButton {...tid('tab', props.children.split(/\s/)[0])} {...props} />

const TabPane = ({children, ...props}: ComponentProps<typeof S.TabPane>) => {
  // If there's no Group in the Tab to provide an hr between content and
  // buttons, go ahead and wrap the content in a Group.
  const hasGroup = defrag(children).some(
    child =>
      isValidElement(child) && (child.type === Group || child.type === SubTabs),
  )
  return (
    <S.TabPane {...props}>
      {hasGroup ? children : <Group>{children}</Group>}
    </S.TabPane>
  )
}

const TabStrip = S.TabStrip

interface TabsProps {
  children: ReactNode
}

export const Tabs = ({children}: TabsProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const childArray = defrag(children)
    .filter(isValidElement)
    .filter(R.path('props.title'))

  const adminIndex = childArray.length

  const [adminTab, setAdminTab] = useState<boolean | null>(null)

  const adminGroupRef = useRef<HTMLDivElement>(null)

  const strip = (
    <TabStrip>
      {childArray.map(
        // @ts-expect-error
        ({props: {title}}, i) => (
          <TabButton
            key={i}
            active={i === activeIndex}
            onClick={() => setActiveIndex(i)}
          >
            {title}
          </TabButton>
        ),
      )}
      {adminTab !== false && (
        <TabButton
          active={activeIndex === adminIndex}
          onClick={() => setActiveIndex(adminIndex)}
        >
          Admin
        </TabButton>
      )}
    </TabStrip>
  )

  const panes = (
    <>
      {childArray.map((child, i) => (
        <TabPane key={i} active={i === activeIndex}>
          {
            // unwrap Tab (don't render as div) so TabPane can check for Group,
            // so that we get an hr between the content and the buttons at the
            // bottom of the dialog.
            // @ts-expect-error
            child?.props?.children || child
          }
        </TabPane>
      ))}
      {adminTab !== false && (
        <TabPane active={activeIndex === adminIndex}>
          <Group
            ref={adminGroupRef}
            style={{
              paddingLeft: '0',
              paddingRight: '0',
              fontSize: 'inherit',
            }}
          />
        </TabPane>
      )}
    </>
  )

  // Move admin fields into the Admin tab on mount. This moves them from outside
  // the React root into it, so we have to make sure to restore them to their
  // original position when the React root is destroyed.
  //
  // This must be useLayoutEffect instead of useEffect, otherwise the cleanup
  // function is deferred until after the component unmounts, and it doesn't run
  // in time to restore the form inputs.
  useLayoutEffect(() => {
    const limb = log.logger('Tabs.useLayoutEffect')

    const moveTo = adminGroupRef.current
    if (!moveTo) {
      limb.error(`moveTo is undefined`)
      return
    }
    const moveFromId = '#dialog-layout-block-properties-core'
    const moveFrom = moveTo.closest(moveFromId) as HTMLDivElement

    if (!moveFrom) {
      // Except in Storybook admin panel, moveFrom should never be undefined.
      limb.assert(IS_DEV, `can't find ${moveFromId}`)
      return
    }

    const allChildren = Array.from(moveFrom.childNodes)
    const [dropMe, ...moveMe] = R.piped(
      allChildren,
      dropWhile((x: ChildNode) => x.textContent?.trim() !== 'Administration'),
      takeWhile(
        (x: ChildNode) =>
          x.nodeType !== 1 ||
          !(x as HTMLElement).querySelector('input[type=submit]'),
      ),
    )
    if (dropMe) {
      limb.debug?.('dropping', dropMe)
      dropMe.remove()
    }
    moveMe.forEach(x => {
      limb.debug?.('moving', x)
      moveTo.appendChild(x)
    })
    setAdminTab(limb.pipe('setAdminTab')(!!moveMe.length))

    // Move fields out of the React root on unmount so the fields aren't lost,
    // and can be rolled up in sendUpdateBlockPropertiesRequest (cubchicken
    // aa_admin/ajaxFuncs.js).
    return () => {
      limb.debug?.('running cleanup')
      moveMe.forEach(x => {
        limb.debug?.('restoring', x)
        moveFrom.appendChild(x)
      })
    }
  }, [])

  return (
    <>
      {strip}
      {panes}
    </>
  )
}

interface TabProps {
  title: string
  children: ReactNode
}

/**
 * This never actually renders as a div, unless it's accidentally used outside
 * of <Tabs>.
 */
export const Tab = ({title, ...props}: TabProps) => <div {...props} />

const SubTabPane = ({
  children,
  ...props
}: ComponentProps<typeof S.SubTabPane>) => {
  // If there's no Group in the Tab to provide an hr between content and
  // buttons, go ahead and wrap the content in a Group.
  const hasGroup = defrag(children).some(
    child => isValidElement(child) && child.type === Group,
  )
  return (
    <S.SubTabPane {...props}>
      {hasGroup ? children : <Group>{children}</Group>}
    </S.SubTabPane>
  )
}

const SubTabStrip = S.SubTabStrip

const SubTabButton = ({
  children,
  ...props
}: Omit<ComponentProps<typeof S.SubTabButton>, 'children'> & {
  children: string
}) => (
  <S.SubTabButton {...tid('subtab', children.split(/\s/)[0])} {...props}>
    <span>{children}</span>
  </S.SubTabButton>
)

interface SubTabsProps {
  children: ReactNode
}

export const SubTabs = ({children}: SubTabsProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const childArray = defrag(children)
    .filter(isValidElement)
    .filter(R.path('props.title'))

  const strip = (
    <SubTabStrip>
      {childArray.map(
        // @ts-expect-error
        ({props: {title}}, i) => (
          <Fragment key={i}>
            {i !== 0 && <span className="pipe">|</span>}
            <SubTabButton
              active={i === activeIndex}
              onClick={() => setActiveIndex(i)}
            >
              {title}
            </SubTabButton>
          </Fragment>
        ),
      )}
    </SubTabStrip>
  )

  const panes = (
    <>
      {childArray.map((child, i) => (
        <SubTabPane key={i} active={i === activeIndex}>
          {
            // unwrap SubTab (don't render as div) so SubTabPane can check for
            // Group, so that we get an hr between the content and the buttons
            // at the bottom of the dialog.
            // @ts-expect-error
            child?.props?.children || child
          }
        </SubTabPane>
      ))}
    </>
  )

  return (
    <>
      {strip}
      {panes}
    </>
  )
}

interface SubTabProps {
  title: string
  children: ReactNode
}

/**
 * This never actually renders as a div, unless it's accidentally used outside
 * of <SubTabs>.
 */
export const SubTab = ({title, ...props}: SubTabProps) => <div {...props} />

export const RowItem = S.Box

export const Row = S.Row

export const Column = S.Column

type Deriver = (props: {from: string; value: any; prev: any; to: string}) => any

type Cascader = {from: string; to: string; derive: Deriver}

interface UseCascadeProps {
  cascade:
    | {from: string | string[]; derive: Deriver}
    | {to: string | string[]; derive: Deriver}
    | Cascader[]
    | Falsish
  name: string
}

const useCascade = ({cascade, name}: UseCascadeProps): ReactElement[] => {
  if (!cascade) return []
  return (Array.isArray(cascade) ? cascade : [])
    .concat(
      ensureArray((cascade as any)?.to).map((to: string) => ({
        ...cascade,
        from: name,
        to,
      })) as Cascader[],
      ensureArray((cascade as any)?.from).map((from: string) => ({
        ...cascade,
        from,
        to: name,
      })) as Cascader[],
    )
    .map(({from, to, derive}) => (
      <Final.Field key={to} name={to} subscription={{}}>
        {(
          // No subscription. We only use Field to get the change function.
          {input: {onChange: changeTo}},
        ) => (
          <Listen.OnChange name={from}>
            {(value, prev) => {
              const derived = derive({from, value, prev, to})
              if (!R.isNil(derived)) {
                changeTo(derived)
              }
            }}
          </Listen.OnChange>
        )}
      </Final.Field>
    ))
}

const adminField = (displayName: any, Component: any, defaults?: any) => {
  // This must be dynamic (instead of moving ComponentWrapper to the top level)
  // so we can set displayName.
  const ComponentWrapper = (props: any) => {
    let {
      cascade,
      colon = true,
      hidden,
      label,
      name,
      snug,
      ...rest
    }: any = {...defaults, ...props}
    const cascaders = useCascade({name, cascade})

    if (colon && typeof label === 'string' && /\w$/.test(label)) {
      label += ':'
    }

    return (
      <S.AdminField hidden={hidden} snug={snug}>
        <Component name={name} label={label} {...rest} />
        {cascaders}
      </S.AdminField>
    )
  }
  Component.displayName = `A.${displayName}`
  ComponentWrapper.displayName = `A.${displayName}Wrapper`
  return ComponentWrapper
}

export const Label = S.Label

export const Hint = S.Hint

export const AdminField = S.AdminField

/**
 * Final.Field that preserves empty string values.
 * https://final-form.org/docs/react-final-form/types/FieldProps#parse
 */
export const Field = (({
  ref,
  component: Component,
  format,
  name,
  parse = R.identity,
  ...props
}: any) => {
  const fieldProps = {format, name, parse, ref}
  return (
    typeof Component === 'string' ?
      <Final.Field component={Component} {...fieldProps} {...props} />
    : Component ?
      <Final.Field {...fieldProps} {...props}>
        {({input, meta}) => <Component {...props} {...input} {...meta} />}
      </Final.Field>
    : <Final.Field {...fieldProps} {...props} />
  )
}) as typeof Final.Field<any>

export const Input = adminField(
  'Input',
  ({fluid, hint, label, labelId, options, ...props}: any) => {
    const id = useId()
    const listId = useId()
    return (
      <div>
        <div>
          {!!label && (
            <Label htmlFor={id} id={labelId}>
              {label}
            </Label>
          )}
          <Field
            id={id}
            component={S.Input}
            type="text"
            list={listId}
            {...props}
            style={{
              ...(fluid && {width: '100%'}),
              ...props.style,
            }}
          />
          {!!options && (
            <datalist id={listId}>
              {(options as OptionVL[]).map(({value}, i) => (
                <option key={i} value={value} />
              ))}
            </datalist>
          )}
        </div>
        {!!hint && <Hint>{hint}</Hint>}
      </div>
    )
  },
)

interface TaggerProps {
  label: string
  name: string
  taggerParentElId?: string
  currentType?: string
  defaultProp?: string
  turnOnTarget?: string
  options?: any
  extraTagGatheringInfo?: any
}

export const Tagger: FC<TaggerProps> = ({
  label,
  name,

  // Following are in order of tagger constructor, omitting the first: callerId
  taggerParentElId = 'admin-edit-area',
  currentType = 'SubjectCollection',
  defaultProp = 'AdminTags',
  turnOnTarget = '#f .ftarget',
  options,
  extraTagGatheringInfo,

  ...props
}) => {
  // const context = useAdminContext()

  // The tagger works with ids instead of elements. React's useId ensures
  // a unique and consistent id.
  const id = useId()

  // The tagger also requires the label to have an id constructed this way,
  // otherwise it will throw an error.
  const labelId = `${id}-label`

  // The tagger attempts to update the input by assigning to .value, but this
  // doesn't work for a controlled field. Instead we pass an onChange callback
  // which will set the value in the Final Form store and rerender the
  // controlled field. (If we were inside Field at this point, then we would
  // have onChange available to us, but this component wraps Field, so we use
  // the Form API directly.)
  // const {change} = Final.useForm()

  // Track the instantiated tagger, so that we don't accidentally instantiate it
  // twice from an unexpected ref callback.
  // const tagger = React.useRef()

  // useRef here creates a stable callback, similar to useCallback but without
  // the dependencies since we're not going to attempt to update the
  // instantiated tagger.
  const {current: ref} = useRef((_el: any) => {
    // DISABLED because of issues in the admin-supplied tag picker, especially
    // confusion between names and displayName.
    /*
    if (el && !tagger.current) {
      tagger.current = new window.tagger(
        el.id,
        taggerParentElId,
        currentType,
        defaultProp,
        turnOnTarget,
        {
          propSelectorText: label,
          canAdd: false,
          requestTagValuesFunc: window.ajaxRequestPickerTagValues,
          onChange: R.partial(change, [name]),
          ...options,
        },
        {
          siteDisplayId: context.siteDisplayId,
          typeFilterList: context.metaTypes,
          ...extraTagGatheringInfo,
        },
      )
    }
    */
  })

  return (
    <Input
      ref={ref}
      id={id}
      labelId={labelId}
      label={label}
      name={name}
      {...props}
    />
  )
}

interface TaggersProps {
  name: string
}

export const Taggers: FC<TaggersProps> = ({name, ...props}) => (
  <>
    <Tagger {...props} label="Matching any" name={`${name}.any`} />
    <Tagger {...props} label="Matching all" name={`${name}.all`} />
    <Tagger {...props} label="Excluding" name={`${name}.excluded`} />
  </>
)

type OptionsObj<K extends string = string> = {
  [value in K]: ReactNode
}

type OptionVL<K extends string = string> = {
  value: K
  label: ReactNode
}

export const options = <K extends string>(os: OptionsObj<K>): OptionVL<K>[] =>
  R.toPairs(os).map(([value, label]) => ({value: value as K, label}))

export const useOptions = <K extends string>(
  os: OptionsObj<K>,
): OptionVL<K>[] => useMemo(() => options(os), [os])

const Option = (props: any) => <S.Option {...props} />

const OptionLabel = (props: any) => <S.OptionLabel {...props} />

export const Radio = adminField(
  'Radio',
  ({hint, label, options, ...props}: any) => {
    return (
      <div>
        {!!label && <Label display="block">{label}</Label>}
        {(options as OptionVL[]).map(o => (
          <S.Option key={o.value}>
            <Label {...tid(props.name, o.value)}>
              <Field
                component={S.Input}
                type="radio"
                value={o.value}
                {...props}
              />
              <OptionLabel>{o.label}</OptionLabel>
            </Label>
          </S.Option>
        ))}
        {!!hint && <Hint>{hint}</Hint>}
      </div>
    )
  },
)

export const YesNo = (props: any) => {
  const options = [
    {value: true, label: props.yes || 'Yes'},
    {value: false, label: props.no || 'No'},
  ]
  if (Object.keys(props).find(k => k === 'yes' || k === 'no') === 'no') {
    options.reverse()
  }
  return <Radio options={options} parse={(v: any) => v === 'true'} {...props} />
}

// Biggest hack ever. "change" is stable reference, so use it as a key into
// a WeakMap to save field values for restoration if we have to force them.
// Eventually should add another provider in InnerBlockAdmin to supply this
// storage instead of a WeakMap.
const FORM_STORAGE = new WeakMap()
const useFormStorage = () => {
  const {change: key} = Final.useForm()
  let storage: Record<string, any> | undefined = FORM_STORAGE.get(key)
  if (!storage) {
    storage = {}
    FORM_STORAGE.set(key, storage)
  }
  return storage
}

const useForceCheckboxProps = ({name, force}: any) => {
  const {change, getState} = Final.useForm()
  const value = getIn(getState().values, name)
  const saved = useFormStorage()

  useEffect(() => {
    if (force !== undefined) {
      if (value !== force) {
        log.log(
          `useForceCheckboxProps: forcing ${name} to ${force} (was: ${value})`,
        )
        saved[name] ??= value
        change(name, force)
      }
    } else if (saved[name] !== undefined) {
      if (value !== saved[name]) {
        log.log(
          `useForceCheckboxProps: restoring ${name} to ${saved[name]} (was: ${value})`,
        )
        change(name, saved[name])
      }
      delete saved[name]
    }
  }, [name, value, force, change, saved])
  return force === undefined ? {} : {disabled: true}
}

const Checkbox = ({hint, label, left, name, force, ...props}: any) => {
  const forceProps = useForceCheckboxProps({name, force})
  return (
    <div>
      <Label>
        {label && !left && <OptionLabel left>{label}</OptionLabel>}
        <Field
          type="checkbox"
          name={name}
          component={S.Input}
          {...props}
          {...forceProps}
        />
        {label && left && <OptionLabel>{label}</OptionLabel>}
      </Label>
      {!!hint && <Hint>{hint}</Hint>}
    </div>
  )
}

export const CheckLeft = adminField('CheckLeft', Checkbox, {
  colon: false,
  left: true,
})

export const CheckRight = adminField('CheckRight', Checkbox)

/**
 * If the options change so that the current value is no longer valid, reset it
 * to the first option.
 *
 * Then, if the options change so that the previously-selected value becomes
 * valid, restore it.
 */
const useValidOption = ({
  name,
  noneOption,
  options: _options,
  format = R.identity,
  parse = R.identity,
}: {
  name: string
  noneOption: any
  options: OptionVL[] | undefined
  format: any
  parse: any
}) => {
  const {change, getState} = Final.useForm()
  const value = format(getIn(getState().values, name))
  const saved = useFormStorage()

  const options: OptionVL[] = useMemo(() => {
    let options = _options || []
    if (
      (noneOption || typeof noneOption === 'string') &&
      !options.some(o => o.value === '')
    ) {
      const label = typeof noneOption === 'string' ? noneOption : '(none)'
      options = [{value: '', label}, ...options]
    }
    return options
  }, [_options, noneOption])

  useEffect(() => {
    const [state, setState] = [
      saved[name],
      (v?: any) => {
        log.log({name, v})
        if (v === undefined) {
          delete saved[name]
        } else {
          saved[name] = v
        }
      },
    ] as const
    if (!options.some(o => o.value === value)) {
      const validValue = options[0]?.value
      if (validValue !== undefined) {
        log.log(
          `useValidOption: setting ${name} to valid value: "${validValue}" (was: "${value}")`,
        )
        setState({invalidValue: value, ...state, validValue})
        change(name, parse(validValue))
      }
    } else if (state) {
      if (value !== state.validValue) {
        log.log(`useValidOption: clearing ${name} state`)
        setState()
      } else if (options.some(o => o.value === state.invalidValue)) {
        log.log(
          `useValidOption: restoring ${name} to previous value: "${state.invalidValue}"`,
        )
        change(name, parse(state.invalidValue))
        setState()
      }
    }
  }, [options, change, saved, name, parse, value])

  return {options}
}

export const Select = adminField('Select', (allProps: any) => {
  const {
    hint,
    label,
    noneOption,
    options: _options,
    fluid,
    name,
    parse,
    format,
    ref,
    ...props
  } = allProps

  const {options} = useValidOption({
    name,
    noneOption,
    options: _options,
    format,
    parse,
  })

  return (
    <div>
      {!!label && <Label>{label}</Label>}
      <Field
        ref={ref}
        type="select"
        name={name}
        parse={parse}
        format={format}
        {...props}
      >
        {({input}: any) => (
          <select
            {...props}
            {...input}
            className={'db' /* prevent admin js from hiding selects! */}
            style={{
              ...(fluid && {width: '100%'}),
              ...props.style,
            }}
          >
            {options.map(o => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </select>
        )}
      </Field>
      {!!hint && <Hint>{hint}</Hint>}
    </div>
  )
})

const checklistParser = (_v: string) => (vs: string[]) => {
  // Final Form passes array with value added or removed.
  return vs
}

const checklistFormatter = (v: string) => (vs?: string[]) => {
  // Final Form wants an array containing or omitting value.
  // We could pass back vs here, it would be the same thing.
  return vs?.includes(v) ? [v] : []
}

const unchecklistParser = (v: string) => (vs: string[]) => {
  // Final Form passes array with value added or removed.
  // (In fact, in the "added" case, it will be doubled since the incoming list
  // of values is the unchecked list.)
  // For Unchecklist, we need to invert the given value.
  return vs.includes(v) ? vs.filter(vv => vv !== v) : vs.concat(v)
}

const unchecklistFormatter = (v: string) => (vs?: string[]) => {
  // Final Form wants an array containing or omitting value.
  // Invert the given value, so that the presentation is the opposite.
  return vs?.includes(v) ? [] : [v]
}

export const Checklist = adminField(
  'Checklist',
  ({
    hint,
    label,
    options,
    parser = checklistParser,
    formatter = checklistFormatter,
    ...props
  }: any) => {
    return (
      <div>
        {!!label && <Label display="block">{label}</Label>}
        {(options as OptionVL[]).map(o => (
          <Option key={o.value}>
            <Label>
              <Field
                component={S.Input}
                type="checkbox"
                value={o.value}
                parse={parser(o.value)}
                format={formatter(o.value)}
                {...props}
              />
              <OptionLabel>{o.label}</OptionLabel>
            </Label>
          </Option>
        ))}
        {!!hint && <Hint>{hint}</Hint>}
      </div>
    )
  },
)

export const Unchecklist = (props: ComponentProps<typeof Checklist>) => (
  <Checklist
    parser={unchecklistParser}
    formatter={unchecklistFormatter}
    {...props}
  />
)

export const Textarea = adminField(
  'Textarea',
  ({hint, label, ...props}: any) => {
    const id = useId()
    return (
      <div>
        {!!label && (
          <div>
            <Label htmlFor={id}>{label}</Label>
          </div>
        )}
        <div>
          <Field
            id={id}
            component="textarea"
            rows="8"
            cols="75"
            {...props}
            style={{maxWidth: '100%', ...props.style}}
          />
        </div>
        {!!hint && <Hint>{hint}</Hint>}
      </div>
    )
  },
)

export const Gravity = adminField(
  'Gravity',
  ({ref, hint, label, name}: any) => {
    return (
      <div ref={ref}>
        {!!label && (
          <div>
            <Label>{label}</Label>
          </div>
        )}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 14px)',
            gridTemplateRows: 'repeat(3, 14px)',
            gap: '4px',
          }}
        >
          {['nw', 'n', 'ne', 'w', '', 'e', 'sw', 's', 'se'].map(value => (
            <Field type="radio" name={name} value={value} key={value}>
              {({input}: any) => <input {...input} />}
            </Field>
          ))}
        </div>
        {!!hint && <Hint>{hint}</Hint>}
      </div>
    )
  },
)

interface NavOutProps {
  tab: 'Design'
  subtab: 'features' | 'nav'
}

export const NavOut: FC<NavOutProps> = ({tab, subtab}) => {
  const context = useAdminContext()
  // TODO UniversalLink?
  return (
    <a
      href="#"
      onClick={e => {
        e.preventDefault()
        const db = (e.target as HTMLElement).closest('.dialogbox')
        if (db instanceof HTMLElement) {
          window.hideDialog(db)
        }
        window.navOut(
          `ajax_ObjectView("${tab}", ${context.siteDisplayId}, "${subtab}")`,
          'eval',
        )
      }}
    >
      Presentation / {subtab === 'features' ? 'Features' : 'Nav & Footer'}
    </a>
  )
}

export type {BlockConfig, GlobalBlockConfig} from 'quickstart/types'

export const Dropdown = ({
  children,
  options,
  onChoose,
}: {
  children: ReactNode
  options: Array<{value: string; label?: ReactNode}>
  onChoose: (value: string) => void
}) => {
  return (
    <AK.MenuProvider>
      <AK.MenuButton
        render={
          typeof children === 'string' ? <S.Button /> : <S.UnstyledButton />
        }
      >
        {children}
      </AK.MenuButton>
      <AK.Menu render={<S.Menu />}>
        {options.map(({value, label}, i) =>
          value === '---' ?
            <AK.MenuSeparator
              key={i}
              style={{
                display: 'block',
                border: 'none',
                borderTop: '1px solid black',
              }}
            />
          : <AK.MenuItem
              key={i}
              render={<S.MenuItem />}
              onClick={() => onChoose(value)}
            >
              {label || value}
            </AK.MenuItem>,
        )}
      </AK.Menu>
    </AK.MenuProvider>
  )
}

export const Icon = {
  Bookshelf: FiBook,
  Check: FiCheck,
  ChevronDown: FiChevronDown,
  CirclePlus: FiPlusSquare,
  Cpu: FiCpu,
  DragHandle: RxDragHandleDots2,
  Link: FiLink,
  Menu: FiMenu,
  More: FiMoreVertical,
  Plus: FiPlus,
  Right: FiArrowRight,
  Separator: FiMoreHorizontal,
  Trash: FiTrash,
  User: FiUser,
}

export const containerOptions = {
  default: (
    <span>
      Automatic &mdash;{' '}
      <a
        href="https://baymard.com/blog/line-length-readability"
        target="_blank"
        rel="noreferrer"
      >
        optimal line length of approximately 75 characters.
      </a>
    </span>
  ),
  wider: (
    <span>
      Relaxed &mdash; trades readability for using more of the available
      horizontal space.
    </span>
  ),
  none: (
    <span>
      Full-width &mdash; use with caution, since it may hinder readability.
    </span>
  ),
}

export const justificationOptions = {
  left: 'Left justified',
  center: 'Centered',
  right: 'Right justified',
}
