import {deepMerge, logger} from 'tizra'
import * as A from '../admin'
import * as v1 from './v1'

const log = logger('HeaderBlock/admin')

interface SortSpec {
  prop: string
  dir: 'ascending' | 'descending'
}

const MODES = ['auto', 'custom', 'prop'] as const

export interface ActionViewSpec {
  mode: (typeof MODES)[number]
  prop: string
  custom: string
}

type ActionDestSpec = ActionViewSpec

export interface ActionSpec {
  view: string
  label: ActionViewSpec
  dest: ActionDestSpec
}

export type Alignment = 'left' | 'center' | 'right'

export interface HeaderBlockConfig extends A.BlockConfig {
  VERSION: 2
  mode: 'post' | 'publication'

  // publication mode
  alignment: Alignment
  headingSize: 'small' | 'normal' | 'large'
  layout: 'above' | 'below' | 'left' | 'right' | 'none'
  headingProp: string
  subHeadingProp: string
  otherProps: string[]
  message: string
  callsToAction: {
    gain: {
      requiredTags: string
      sortBy: SortSpec
    }
    exercise: {
      actions: ActionSpec[]
    }
  }

  // other modes
  post: {
    heading: {
      prop: string
    }
    authors: {
      prop: string
      strategy: 'none' | 'browse' | 'search' | 'object'
      browse: {
        url: string
        param: string
      }
      object: {
        metaType: string
        prop: string
      }
    }
    date: {
      prop: string
    }
    tags: {
      prop: string
      strategy: 'none' | 'browse' | 'search'
      browse: {
        url: string
        param: string
      }
    }
  }

  v1?: v1.Config
}

export const defaultConfig: HeaderBlockConfig = {
  VERSION: 2,
  mode: 'publication',

  // publication mode
  alignment: 'center', // only applies to single column
  headingSize: 'normal',
  layout: 'right',
  headingProp: '_name',
  subHeadingProp: 'Authors',
  otherProps: [],
  message: '',
  callsToAction: {
    gain: {
      requiredTags: '',
      sortBy: {prop: '', dir: 'ascending'},
    },
    exercise: {
      actions: [
        {
          view: 'page',
          label: {mode: 'auto', prop: '', custom: ''},
          dest: {mode: 'auto', prop: '', custom: ''},
        },
        {
          view: 'sourceDownload',
          label: {mode: 'auto', prop: '', custom: ''},
          dest: {mode: 'auto', prop: '', custom: ''},
        },
      ],
    },
  },

  // other modes
  post: {
    heading: {
      prop: '_name',
    },
    authors: {
      prop: 'Authors',
      strategy: 'search', // because zero config
      browse: {
        url: '/posts',
        param: 'browseParam-filter0',
      },
      object: {
        metaType: '',
        prop: '',
      },
    },
    date: {
      prop: '',
    },
    tags: {
      prop: 'Keywords',
      strategy: 'search', // because zero config
      browse: {
        url: '/posts',
        param: 'browseParam-filter1',
      },
    },
  },

  FREEMARKER: {
    message: 'markdown',
  },
}

/**
 * Migrate a previous, empty or nullish config to v2.
 */
export const migrate: A.Migrate<HeaderBlockConfig> = props => {
  const isAdmin = 'adminContext' in props
  const unknown = props.config as any

  if ((unknown?.VERSION ?? 0) > 2) {
    log.error(`VERSION=${unknown.VERSION} in migrate`)
    return unknown as HeaderBlockConfig
  }

  // This type declaration means that anything we assign to config below must
  // sparsely match the shape of the v2 config.
  let config: Partial<HeaderBlockConfig> | undefined =
    unknown?.VERSION === 2 ? (unknown as HeaderBlockConfig) : undefined

  if (!config) {
    // Only need to call prior version migrate; it will call to its prior as
    // needed.
    const c1: v1.Config = v1.migrate(props)

    // We are guaranteed to have a fully-populated v1 config object now.
    config = {
      VERSION: 2,
      alignment: c1.alignment,
      headingSize: c1.headingSize,
      layout: c1.layout,
      subHeadingProp: c1.subHeadingProp,
      headingProp: c1.headingProp,
      otherProps: c1.otherProps,
      callsToAction: {
        ...defaultConfig.callsToAction, // make TS happy
        exercise: {
          actions: c1.callsToAction.canAccess.buttons
            .map(migrateButton)
            .filter(Boolean) as ActionSpec[],
        },
      },
      // message migrated below, and pseudo-migrated in Message.tsx
    }

    // v1 config has three messages and two buttons that aren't representable by
    // the v2 config (sign in and contact). These need to be consolidated to
    // a single Freemarker message. There are two paths for consolidation:
    //
    // - admin: Do the migration here, and the resulting Freemarker will be
    // saved to the v2 config.
    //
    // - render: Do a pseudo migration by holding on to the v1 config, and then
    // handle that specially in the v2 Header Block.
    //
    if (isAdmin) {
      config.message = migrateMessage(c1)
    } else {
      config.v1 = c1
    }
  }

  // There was a bug in the v2 admin form that would store an ActionViewSpec or
  // ActionDestSpec incorrectly: {mode: 'prop', prop: 'Foo'} would instead be
  // stored as {mode: 'Foo'}.
  for (const a of config.callsToAction?.exercise?.actions || []) {
    for (const v of [a.label, a.dest]) {
      if (!MODES.includes(v.mode)) {
        v.prop = v.mode
        v.mode = 'prop'
      }
    }
  }

  return deepMerge(defaultConfig)(config)
}

/**
 * Migrate v1 canAccess button (READ or DOWNLOAD) to v2.
 */
function migrateButton(b1: v1.ActionButton): ActionSpec | null {
  if (!b1) {
    log.warn('migrateButton received nullish v1.ActionButton, ignoring')
    return null
  }
  const view =
    b1.action === 'READ' ? 'page'
    : b1.action === 'DOWNLOAD' ? 'sourceDownload'
    : null
  return (
    view && {
      view,
      label: {
        mode: b1.customLabel?.trim() ? 'custom' : 'auto',
        prop: '', // wasn't supported in v1
        custom: b1.customLabel || '',
      },
      dest: {
        mode: b1.linkProp ? 'prop' : 'auto',
        prop: b1.linkProp || '',
        custom: '', // wasn't supported in v1
      },
    }
  )
}

/**
 * Migrate v1 noAccessSignedIn/noAccessSignedOut button (CONTACT or LOGIN), also
 * used in pseudo migration in Message.tsx. Incomplete because we don't honor
 * linkProp, but it's more work and complexity, and it's really unlikely.
 */
export function migrateButtonToMarkdown(b: v1.ActionButton | undefined) {
  switch (b?.action) {
    case 'CONTACT':
      return `<button type="button" onclick="window.location='/~contactUs';return false" style="min-width: 50%">${
        b.customLabel || 'Contact us'
      }</button>`
    case 'LOGIN':
      return `<button type="button" onclick="tizra.login();return false" style="min-width: 50%">${
        b.customLabel || 'Sign in'
      }</button>`
    default:
      return ''
  }
}

/**
 * Extract the [#ftl] directive from the Freemarker, since it's illegal to have
 * it anywhere but the front.
 */
const extractFtlDirective = (
  message: string,
): {ftlDirective?: string; message: string} => {
  const m = message.match(/^\s*(\[#ftl.?\])(.*)/s)
  return m ? {ftlDirective: m[1], message: m[2]} : {message}
}

/**
 * Migrate v1 messages to v2 consolidated message. This runs in the admin.
 * Once this is saved, the pseudo-migration in Message.tsx won't bother.
 */
function migrateMessage(c1: v1.Config): string {
  const {
    messages,
    messages: [canAccess, noAccessSignedOut, noAccessSignedIn],
  } = {
    messages: [
      extractFtlDirective(c1.callsToAction.canAccess.message),
      extractFtlDirective(c1.callsToAction.noAccessSignedOut.message),
      extractFtlDirective(c1.callsToAction.noAccessSignedIn.message),
    ],
  }

  // Hopefully if one has an [#ftl] directive, all of them have it. But in any
  // case, we'll use the first one that we find.
  const ftlDirective = messages.find(x => x.ftlDirective)?.ftlDirective || ''

  // BEWARE ${...} is JS, \${...} is Freemarker.
  return `${ftlDirective}
[#if lib.viewIsAccessible('page') || lib.viewIsAccessible('sourceDownload')]

${canAccess.message}

[#elseif !lib.hasRelevantOffers()]

[#if !lib.loggedIn()]

${migrateButtonToMarkdown(c1.callsToAction.noAccessSignedOut.buttons[0])}

${noAccessSignedOut.message}

[#else]

${migrateButtonToMarkdown(c1.callsToAction.noAccessSignedIn.buttons[0])}

${noAccessSignedIn.message}

[/#if]
[/#if]
  `
    .replace(/\n{3,}/g, '\n\n')
    .trim()
}
