import {blockMeta} from '../meta'

export interface DetailsBlockConfig {
  heading: string
  excludes: string[]
}

const defaultConfig: DetailsBlockConfig = {
  heading: 'Details',
  excludes: [
    'Abstract',
    'Authors',
    'CoverImage',
    'Description',
    'Name',
    'Title',
  ],
}

export const meta = blockMeta({
  name: 'content-details',
  displayName: 'DetailsBlock',
  title: 'Details',
  defaultConfig,
})
