import {Dict, Opt, notNullish} from 'quickstart/types'
import * as R from 'rambdax'
import {
  SearchTypes,
  api,
  deepMerge,
  delay,
  logger,
  magicSort,
  meta,
} from 'tizra'
import {DEFAULTS} from './default-config'
import FIELD_TYPES from './field-types'
import {
  FieldDefinition,
  MergedDefinition,
  SearchConfig,
  SearchConfigWithSparseFields,
  SearchField,
  ServerDefinition,
  SparseConfig,
  SparseField,
} from './types'

const log = logger('search/config')

export const CONFIGURED = {
  UNCONFIGURED: 0,
  INITIALIZED: 1, // merged searchConfig with default config
  TYPES: 2, // integrated search-types response
  FIELDS: 3, // field promises (prop-values) fulfilled
} as const

const SUPPORTED_TYPES = [
  //'ISBN', // ISBN
  //'ISBN-13', // ISBN-13
  //'auto-uuid', // Automatic UUID
  'boolean', // True/False
  'boolean-list', // Keyword Checklist
  'css-color', // CSS Hex Color
  //'date', // Date
  //'encrypted-url', // Amazon secure url base
  //'float', // Number with Decimals
  //'float-list', // Number with Decimals List
  //'html', // HTML
  //'integer', // Number
  //'integer-list', // Number List
  //'json-array', // JSON Array
  //'json-hash', // JSON Hash
  //'json-value', // JSON Value
  'keyword', // Single Keyword
  'keyword-list', // Keyword List
  //'reference', // Object Reference (read-only)
  'string', // String
  'string-list', // String List
  //'text', // Text
]

/**
 * Initialize config from defaults and config param, which was historically
 * window.tizra.search.config and is now either global or block config.
 *
 * Field options might not be populated yet when this function returns, if
 * they need to call prop-values. In that case, configSaga hooks on each
 * field.promise to trigger the configField action and re-render
 * as necessary.
 */
export function initConfig(
  searchConfig: SparseConfig = {},
  doPromises = false,
): Opt<SearchConfig, 'fieldDefs'> {
  const defaultConfig = DEFAULTS[searchConfig.mode || 'search']
  const config: SearchConfigWithSparseFields =
    deepMerge(defaultConfig)(searchConfig)

  for (const k of ['metadata', 'fulltext'] as const) {
    const s = config.metaTypes?.[k]
    if (typeof s === 'string') {
      config.metaTypes![k] = [s]
    }
  }

  log.debug?.('initConfig enter', {config, searchConfig, doPromises})

  // @ts-expect-error TS thinks there are still null values
  const filteredFields: Dict<SparseField> = R.filter(notNullish, config.fields)

  const fields = R.map<SparseField, SearchField>(
    (field, name) => initField({...field, name, id: name}, doPromises),
    filteredFields,
  )

  const configWithFields = {
    ...config,
    fields,
  }

  log.debug?.('initConfig exit', {config: configWithFields})

  return configWithFields
}

/**
 * Extract uniqueness identifier from a field definition. Unique properties are
 * those with different names and/or types. If a same-named same-typed property
 * exists across multiple meta-types, it can be merged into a single field.
 */
const uniqPropFn = (def: ServerDefinition) => `${def.name}--${def.type}`

/**
 * Make a field name from a field definition. The field definition should have
 * been augmented with the metaType.
 */
const uniqFieldName = (def: FieldDefinition) =>
  `${def.metaType}--${uniqPropFn(def)}`

/**
 * Add/augment fields in config according to search-types API response
 */
export function addSearchTypesToConfig(
  config: Omit<SearchConfig, 'fieldDefs'>,
  searchTypes: SearchTypes,
  doPromises = false,
): SearchConfig {
  const fieldDefs: FieldDefinition[] = R.piped(
    R.values(searchTypes),
    R.chain(t =>
      Object.values(t.propDefsIncludingSubtypes).map(def => ({
        metaType: t.name,
        ...def,
      })),
    ),
    R.sortBy(t => t.sortField),
  )

  const mergedDefs: MergedDefinition[] = R.piped(
    fieldDefs,
    R.reject(def => def.isSystem),
    config.mode === 'search' ? R.filter(def => def.isSearchable) : R.identity,
    defs =>
      R.piped(
        defs,
        R.uniqBy<FieldDefinition, string>(uniqPropFn),
        R.map(def => ({
          combinedName: uniqFieldName(def),
          // collect applicable metaTypes from all searchable defs
          metaTypes: [
            ...new Set(
              defs
                .filter(d => uniqPropFn(d) === uniqPropFn(def))
                .map(d => d.metaType),
            ),
          ].sort(),
          ...def,
        })),
      ),
  )

  for (const def of mergedDefs) {
    config = addFieldToConfig(config, def, doPromises)
  }

  return {
    ...config,
    fieldDefs,
  }
}

/**
 * Returns true if any of the given child meta-types inherits from any of
 * the parent meta-types (or if any of the child meta-types appear in the
 * list of parent meta-types)
 */
export function metaTypesInheritFrom(
  cs: string | string[],
  ps: string | string[],
): boolean {
  // prettier-ignore
  const [children, parents] = R.map(
    xs => Array.isArray(xs) ? xs : [xs],
    [cs, ps]
  )

  // First check if any of the child meta-types appear in the list of
  // parent meta-types. For the sake of how this function is used by
  // callers, this resolves to true.
  for (const c of children) {
    if (parents.includes(c)) {
      return true
    }
  }

  // Second check for the hard-coded relationship between books, excerpts
  // and pages. This might change in the future to refer to the proper
  // meta-type hierarchy retrieved from the server in search-types.
  return (
    parents.includes('Book') ?
      children.includes('PageRange') || children.includes('PdfPage')
    : parents.includes('PageRange') ? children.includes('PdfPage')
    : false
  )
}

export function isConfiguredMetaType(
  config: Omit<SearchConfig, 'fieldDefs'>,
  metaType: string,
) {
  const metaTypes = Object.values(config.metaTypes).flat()
  return (
    metaTypes.includes(metaType) || metaTypesInheritFrom(metaTypes, metaType)
  )
}

/**
 * Add a field to the config from the search-types API response. If the
 * field already exists in config.fields, either by name or by the
 * metaType/prop combination, then augment that field with the
 * server-provided type information.
 */
export function addFieldToConfig(
  config: Omit<SearchConfig, 'fieldDefs'>,
  def: MergedDefinition,
  doPromises = false,
): Omit<SearchConfig, 'fieldDefs'> {
  const existing = Object.values(config.fields).find(
    f =>
      f.name === def.combinedName ||
      (f.prop === def.name &&
        ((!f.metaType && !f.metaTypes) ||
          (f.metaType && def.metaTypes.includes(f.metaType)) ||
          (f.metaTypes && R.intersection(f.metaTypes, def.metaTypes).length))),
  )

  log.debug?.('addFieldToConfig enter', {config, def, doPromises, existing})

  if (!existing && hardIgnore(config, def)) {
    return config
  }

  const field = initField(
    {
      id: def.combinedName,
      name: def.combinedName,
      serverDef: def,
      ...existing,
    } as SearchField,
    doPromises,
  )

  const fields = {
    ...config.fields,
    [field.name]: field,
  }

  const order =
    config.order.includes(field.name) ?
      config.order
    : config.order.concat(field.name)

  config = {
    ...config,
    fields,
    order,
  }

  log.debug?.('addFieldToConfig exit', {config})

  return config
}

/**
 * Hard-coded tests to decide if a def (response from search-types) should
 * be added to the config by addFieldToConfig.
 */
function hardIgnore(
  config: Omit<SearchConfig, 'fieldDefs'>,
  def: MergedDefinition,
): boolean {
  const because = (reason: string) => {
    log.debug?.(`hardIgnore ${def.metaType}-${def.name}: because ${reason}`)
    return true
  }
  if (config.onlyExplicitFields) {
    return because(`onlyExplicitFields`)
  }
  if (config.ignoreProps?.includes(def.name)) {
    return because(`${def.name} is in config.ignoreProps`)
  }
  if (config.mode === 'browse') {
    return because(`mode=${config.mode}`)
  }
  if (!isConfiguredMetaType(config, def.metaType)) {
    return because(`metaType=${def.metaType} isn't supported`)
  }
  if (def.name === 'Title') {
    return because(`name is Title`)
  }
  if (!heuristicFieldType(def)) {
    return because(`type=${def.type} isn't supported`)
  }
  return false
}

/**
 * Heuristically determine a field type based on its definition from
 * search-types.
 */
function heuristicFieldType(
  def: MergedDefinition,
): 'year' | 'prop' | undefined {
  if (
    /Years?(?!=[a-z])|\byears?\b/.test(def.name) &&
    ['integer', 'keyword', 'string'].includes(def.type.replace(/-list$/, ''))
  ) {
    return 'year'
  }
  if (SUPPORTED_TYPES.includes(def.type)) {
    return 'prop'
  }
}

/**
 * Initialize a field from raw config. If doPromises, then kicks off the
 * prop-values fetch to fill in options.
 *
 * This is idempotent, so it can be called repeatedly to augment field
 * information as it is obtained, or to init early with doPromises=false
 * and then later with doPromises=true.
 */
export function initField(
  {...field}: SearchField,
  doPromises = false,
): SearchField {
  const {serverDef: def} = field

  log.debug?.('initField enter', {field, doPromises})

  // METATYPE, PROP and TYPE

  if (def) {
    field = {
      metaTypes: def.metaTypes,
      prop: def.name,
      type: heuristicFieldType(def) || def.type,
      ...field,
    }
  } else if (
    field.metaType &&
    (!field.metaTypes || field.metaTypes.length === 0)
  ) {
    field = {
      metaTypes: [field.metaType],
      ...field,
    }
  }

  // TODO Support year fields in AdvancedConfigField for v3. This temporarily
  // overrides either heuristicFieldType() or any explicit configuration of
  // field.type.
  if (field.type === 'year') {
    field.type = 'prop'
  }

  const fieldType = FIELD_TYPES[field.type as keyof typeof FIELD_TYPES]
  if (fieldType) {
    field = deepMerge<SearchField>(fieldType as SearchField)(field)
  }

  // LABEL

  if (def) {
    const origLabel = field.label
    field = {
      ...field,
      label: meta.plural(origLabel ?? def.displayName),
      filterTags: {
        label: () => meta.singular(origLabel ?? def.displayName).toLowerCase(),
        ...field.filterTags,
      },
    }
  }

  // OPTIONS: list of {value, text} for dropdowns and radios

  const {options} = field
  if (typeof options === 'function') {
    // @ts-expect-error yikes
    field.promise = options // don't call yet
    field.options = []
  } else if (options === undefined && field.prop && field.metaTypes) {
    const {metaTypes} = field
    field.options = []
    field.promise = () =>
      delay(100)
        .then(() =>
          Promise.all(
            metaTypes.map(
              m =>
                (api as any).propValues({
                  'prop-names': [field.prop],
                  'meta-type': m,
                }) as Promise<{[propName: string]: string[]}>,
            ),
          ),
        )
        .then(responses => R.flatten(responses.map(Object.values)))
  } else if (Array.isArray(options)) {
    let os: Array<{value: any; text: string}> = (
      options.map(value =>
        typeof value === 'string' ? {value} : value,
      ) as Array<{value: any; text?: string}>
    ).map(o => ({
      ...o,
      text: o.text || (field.format && field.format(o.value, field)) || o.value,
    }))

    const sortSwitch = `${field.promise ? 'server' : 'config'}|${field.sort}`
    switch (sortSwitch) {
      case 'config|false':
      case 'config|undefined':
      case 'server|false':
        break
      case 'config|magic':
      case 'config|true':
      case 'server|magic':
      case 'server|true':
      case 'server|undefined':
        os = magicSort(o => o.text, os)
        break
      default:
        log.warn(`${field.name}: don't know sortSwitch=${sortSwitch}`)
    }

    // Handle descending years, etc.
    if (field.reverse) {
      os = R.reverse(os)
    }

    // The values must be unique, so enforce that.
    // Double-reverse to keep the last value rather than first.
    os = R.reverse(R.uniqBy(R.prop('value'), R.reverse(os)))

    // This check improves the idempotency of initField by avoiding unnecessary
    // re-renders.
    if (!R.equals(os, field.options)) {
      field.options = os
    }
  }

  if (doPromises && field.promise && field.loading === undefined) {
    field.loading = true

    if (typeof field.promise === 'function') {
      field.promise = Promise.resolve(field.promise(field))
    }

    if (field.processOptions) {
      const {processOptions} = field
      field.promise = field.promise.then(options =>
        processOptions(options, field),
      )
    }

    field.promise = field.promise.then(
      options => ({
        options,
        loading: false,
      }),
      error => {
        log.error(
          `promise rejected for ${field.name}:\nMESSAGE: ${error}\nDETAIL:\n`,
          error.detail,
        )
        return {loading: false}
      },
    )
  }

  // DEFAULT VALUE

  if (field.defaultValue === undefined) {
    if (field.options) {
      if (field.hooks?.defaultValue) {
        if (!field.promise || field.loading === false) {
          field.defaultValue = field.hooks.defaultValue({field})
        }
      } else {
        // Prior to browse, fields with options were historically
        // multi-select by default. So keep that as the default, checking for
        // explicit false.
        field.defaultValue = field.multi === false ? '' : []
      }
    } else {
      log.warn(`initField: ${field.name} lacks defaultValue`)
    }
  }

  if (field.multi === undefined && field.defaultValue !== undefined) {
    field.multi = Array.isArray(field.defaultValue)
  }

  log.debug?.('initField exit', {field})

  return field
}

/**
 * Produce an array of fields for a given form, merging in form overrides.
 */
export function formFields<T = unknown>(
  {fields, order}: SearchConfig,
  formName: string,
): SearchField<T>[] {
  return order
    .map(name => fields[name] as SearchField<T> | null | undefined)
    .filter(notNullish) // drop anything nulled in fields
    .map(field => formField<T>(field, formName))
    .filter(field =>
      typeof field.show === 'function' ?
        field.show(field)
      : field.show !== false,
    )
}

export type NestedFields<T = unknown> = Array<SearchField<T> | NestedFields<T>>

/**
 * Produce a nested array of fields for a given form, merging in form
 * overrides. This is especially for the advanced search form, which groups
 * some fields together (terms/depth, volumes/versions). Grouping is
 * determined by an empty or missing label, which indicates that the given
 * field should be grouped with the field before it.
 */
export function nestedFormFields<T = unknown>(
  config: SearchConfig,
  formName: string,
): NestedFields<T> {
  return formFields<T>(config, formName).reduce(
    (fields: NestedFields<T>, field: SearchField<T>) => {
      if (fields.length && !field.label) {
        const lastField = fields.pop()
        fields.push([lastField!, field].flat())
      } else {
        fields.push(field)
      }
      return fields
    },
    [],
  )
}

export function formField<T = unknown>(
  _field: SearchField<T>,
  formName: string,
): SearchField<T> {
  const field = _field as SearchField<T> & {[formName: string]: SearchField<T>}
  let formField = {...field, ...field[formName]}
  let options = field[formName]?.options
  if (typeof options === 'function') {
    // @ts-expect-error
    options = options(field)
    formField = {...formField, options}
  }
  return formField
}

/**
 * Make a value appropriate for React iterator key.
 */
export function formFieldKey<T = unknown>(
  field: SearchField<T> | NestedFields<T>,
): string {
  return Array.isArray(field) ?
      `${formFieldKey(field[0])}-group`
    : field.key || field.name
}

export * from './types'
