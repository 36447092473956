import {deepMerge} from 'tizra'
import * as A from '../admin'
import {blockMeta} from '../meta'

export interface ThemeBlockGlobalConfig extends A.GlobalBlockConfig {
  buttonColor: string
  linkColor: string
  logoUrl: string
  logoLink: string
  bodyFont: 'sans' | 'serif'
  headingFont: 'sans' | 'serif'
}

// TODO: stop exporting
export const defaultGlobalConfig: ThemeBlockGlobalConfig = {
  buttonColor: '',
  linkColor: '',
  logoUrl: '/aa_shared/quickstart/assets/logo.png',
  logoLink: '/',
  bodyFont: 'sans',
  headingFont: 'sans',
}

// This is special! Instead of A.GlobalMigrate<ThemeBlockGlobalConfig>
// we require only a subset of props. The result is type-compatible but makes it
// easier to implement createBrandedTheme() which needs to run sometimes where
// it's hard to access ThemeBlock.info.globalMigrate (the enhanced wrapper)
// because of circular imports.
// TODO: update branding to use meta.globalMigrate
export const globalMigrate = ({
  globalConfig: unknownGlobalConfig,
}: {
  globalConfig: unknown
}): ThemeBlockGlobalConfig => {
  const globalConfig = {
    ...(unknownGlobalConfig as any),
  } as Partial<ThemeBlockGlobalConfig>
  return deepMerge(defaultGlobalConfig)(globalConfig)
}

export const meta = blockMeta({
  name: 'theme',
  displayName: 'ThemeBlock',
  title: 'Theme', // required but not used
  globalMigrate,
  defaultGlobalConfig,
})
