import * as A from '../admin'
import {blockMeta} from '../meta'

export interface SubnavBlockConfig extends A.BlockConfig {
  heading: string
  match: {
    any: string
    all: string
    excluded: string
  }
}

const defaultConfig: SubnavBlockConfig = {
  heading: '',
  match: {
    any: '',
    all: '',
    excluded: '',
  },
}

export const meta = blockMeta({
  name: 'subnav',
  displayName: 'SubnavBlock',
  title: 'Sub Nav',
  defaultConfig,
})
