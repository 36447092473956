import * as A from '../admin'
import {blockMeta} from '../meta'

export interface AttachmentsBlockConfig extends A.BlockConfig {
  heading: string
  excludedSources: string[]
  filterByUserLicenses: boolean
  iconsEnabled: boolean
  iconSize: 'auto' | 'small' | 'medium' | 'large'
}

const defaultConfig: AttachmentsBlockConfig = {
  heading: 'Resources',
  excludedSources: [],
  filterByUserLicenses: false,
  iconsEnabled: true,
  iconSize: 'auto',
}

export const meta = blockMeta({
  name: 'attachments',
  displayName: 'AttachmentsBlock',
  title: 'Attachments',
  defaultConfig,
})
