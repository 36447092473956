import * as A from '../admin'
import {blockMeta} from '../meta'

export interface RedemptionBlockConfig extends A.BlockConfig {
  heading: string
  message: string
}

const defaultConfig: RedemptionBlockConfig = {
  heading: 'Enter your access code',
  message: '',
  FREEMARKER: {
    message: 'markdown',
  },
}

export interface RedemptionBlockGlobalConfig {
  url: string
}

const defaultGlobalConfig: RedemptionBlockGlobalConfig = {
  url: '/add',
}

export const meta = blockMeta({
  name: 'redemption',
  displayName: 'RedemptionBlock',
  title: 'Redemption Code',
  defaultConfig,
  defaultGlobalConfig,
})
