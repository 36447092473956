import {blockMeta} from '../meta'
import {defaultConfig, migrate} from './v2'

export type {ActionSpec, ActionViewSpec, HeaderBlockConfig} from './v2'
export {defaultConfig, migrate, migrateButtonToMarkdown} from './v2'

export const meta = blockMeta({
  name: 'content-cover',
  displayName: 'HeaderBlock',
  title: 'Header',
  defaultConfig,
  migrate,
})
