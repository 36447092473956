import * as A from '../admin'
import {blockMeta} from '../meta'

export const modes = {
  current: 'Current object',
  all: 'Everything',
  specific: 'Specific collection or publication',
} as const

type MODE = keyof typeof modes

export interface SearchWithinBlockConfig extends A.BlockConfig {
  heading: string
  placeholder: string
  mode: MODE
  specificId: string
}

const defaultConfig: SearchWithinBlockConfig = {
  heading: '',
  placeholder: 'Search within…',
  mode: 'current',
  specificId: '',
  FREEMARKER: {
    heading: 'markdown',
    placeholder: 'markdown',
  },
}

export const meta = blockMeta({
  name: 'search-within',
  displayName: 'SearchWithinBlock',
  title: 'Search Within',
  defaultConfig,
})
