import * as R from 'rambdax'
import {deepMerge, isObjectType} from 'tizra'
import {PartialDeep, SetRequired} from 'type-fest'
import * as A from '../admin'
import {blockMeta} from '../meta'

interface V0 extends A.BlockConfig {
  alignment: 'left' | 'center' | 'right'
  heading: string
  prop: string
}

interface V1 extends A.BlockConfig {
  VERSION: 1
  heading: string
  prop: string
  justification: 'left' | 'center' | 'right'
  position: 'left' | 'center' | 'right'
}

interface V2 extends A.BlockConfig {
  VERSION: 2
  heading: string
  prop: string
  justification: A.Just
  container: 'default' | 'wider' | 'none'
  background: A.BackgroundSpec
}

const defaultV2: V2 = {
  VERSION: 2,
  heading: '',
  prop: '',
  container: 'default',
  justification: 'left',
  background: A.defaultBackgroundSpec,
}

export type AbstractBlockConfig = V2

const defaultConfig = defaultV2

const posToCardinal = (pos?: 'left' | 'center' | 'right'): A.OneD =>
  pos === 'center' ? ''
  : pos === 'right' ? 'e'
  : 'w'

type PV<T extends {VERSION: any}> = SetRequired<PartialDeep<T>, 'VERSION'>

const defaultV1: V1 = {
  VERSION: 1,
  heading: '',
  prop: '',
  justification: 'left',
  position: 'left',
}

const zeroToOne = <C extends PartialDeep<V0>>(config: C): V1 =>
  deepMerge(defaultV1)({
    heading: config.heading,
    prop: config.prop,
    justification: config.alignment,
    position: config.alignment,
  })

const oneToTwo = <C extends PV<V1>>(config: C): V2 =>
  deepMerge(defaultV2)({
    heading: config.heading,
    prop: config.prop,
    justification: config.justification,
    background: {
      foreground: {
        position: posToCardinal(config.position),
      },
    },
  })

const isV0 = (config: unknown): config is PartialDeep<V0> =>
  isObjectType(config) && !('VERSION' in config)

const isV1 = (config: unknown): config is PV<V1> =>
  (config as any)?.VERSION === 1

const migrate: A.Migrate<
  AbstractBlockConfig,
  undefined | PartialDeep<V0> | PV<V1> | PV<V2>
> = ({config}) =>
  R.piped(
    config,
    config => (isV0(config) ? zeroToOne(config) : config),
    config =>
      isV1(config) ? oneToTwo(config) : deepMerge(defaultConfig)(config),
  )

export const meta = blockMeta({
  name: 'content-blurb',
  displayName: 'AbstractBlock',
  title: 'Abstract',
  defaultConfig,
  migrate,
})
