import * as A from '../admin'
import {blockMeta} from '../meta'

export interface HeadlineBlockConfig {
  left: A.BrowseSpec
  right: A.BrowseSpec
}

export const defaultConfig: HeadlineBlockConfig = {
  left: {
    ...A.defaultBrowseSpec,
    metaTypes: ['PageRange'],
    maxItems: '1',
    display: 'headline',
  },
  right: {
    ...A.defaultBrowseSpec,
    metaTypes: ['PageRange'],
    maxItems: '100',
    display: 'titles',
  },
}

export const meta = blockMeta({
  name: 'headline',
  displayName: 'HeadlineBlock',
  title: 'Headline',
  defaultConfig,
})
